<template>
  <div>
    <hr class="hr2">
    <div v-html>
      <div style="text-align: left!important; width: 1112px;    margin-left: 50%;    transform: translate(-50%, 10px);">
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:20px; line-height: 26px; margin:14px 0pt 3pt; orphans:0; text-align:center; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:20px; font-weight:bold">用户</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:20px; font-weight:bold">服务</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:20px; font-weight:bold">协议</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">&#xa0;</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">尊敬的用户，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">您好！</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">请在使用微思翻译系列产品之</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">前，仔细阅读《</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户服务</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">协议》，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">并</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">确保充分理解本协议中各条款。</span></p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">一、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">简介</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:24.6pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本协议为用户与微思翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">平台</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">之间的服务协议，微思翻译本着</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">精细化和个性化服务</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">的原则，竭诚为用户提供</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">机器</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">翻译及相关服务。</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户应当在仔细阅读、充分理解本协议中所有内容，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">确认</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">同意之后，合理使用微思翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">所</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">提供的服务。如本协议中的相关</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">条款</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">无法</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">获得用户</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">认同，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">我们不建议用户</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">使用本产品。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">平台上出现的相关</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">引导、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">操作提示、使用帮助</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">向用户展示</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">使用方法、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">规则、流程等</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">等</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，此类内容</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">是</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">构成</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">服务平台</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">相关规则的一部分，与本协议具有同等效力。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户接受本协议之后，本协议可能</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">会</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">因国家政策、产品与</span><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">/</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">或服务以及履行本协议的环境发生</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">改变</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">而进行修改，修改后的协议</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">将在本平台</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">的适当位置</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">进行展示，请</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">及时</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">查阅</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。如用户在本协议更新后继续使用</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">服务，即表示用户已仔细阅读、充分理解并接受更新后的协议。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">&#xa0;</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">如在阅读本协议过程中有任何疑惑或问题，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">欢迎与我</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">们</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">（北京容智科技发展有限公司）取得联系，我</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">们</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">将尽快为您解答。</span>
        </p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">二、服务内容</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译服务将以有偿或无偿服务的形式进行提供。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">1</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、服务平台网址：包括但不限定于</span><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">w</span><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">esitrans.com</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">wesitrans.cn</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">2</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、用户服务终端：包括但不限定于</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">PC、平板、手机</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">3、用户服务等级：存在机构用户、个人用户的</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">区</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">别，服务方式或者内容略有</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">不同</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。</span></p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">三、用户使用规则</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">1</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">允许</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">合理合法使用</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本服务</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">平台</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">禁止</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">任何以恶意破解</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">为目的</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">等非法手</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">段</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">干扰或破坏</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">服务</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">平台</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">的行为，如有发现，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">我</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">司将立即</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">通知相关</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">执法机关，保</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">留</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">依法追其法律责任的权利，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">由此</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">引发</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">的一切法律后果</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">将</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">由</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">该</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">行为人负责。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">2</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">应通过合法手段获取本平台的使用资格，不得以未经授权的途径获取服务资格，否则将认定为非法获取，一经查获，本平台概不承认其使用资格，并有权立即删除、封号等，收回其使用权利，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">由</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">此产生的一切后果与本平台无关，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">均</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">由用户自行承担。微思翻译将保留依法追究其法律责任的权利。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">3</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">禁止用户在本平台上，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">制作、上传、复制、传送、传播包含任何反对宪法所确定的基本原则、危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一、破坏民族团结、损害国家荣誉和利益、煽动民族仇恨、民族歧视、破坏民族团结、破坏国家宗教政策、宣扬邪教和封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪、侮辱或者诽谤他人，侵害他人合法权益的等法律、行政法规禁止的内容或其</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">它令</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">人反感的包括但不限于资讯、资料、文字、照片、图形、信息或其他资料</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">或者利用账号进行违法、欺骗等违法活动，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">一经</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">发现，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">平台有权不经通知</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">进行</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">封号、注销等，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本平台保留依法报案或者</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">协助公检机关的权利。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">4</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、用户不得</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">故意冒用他人身份</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，使用他人手机号等信息</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">注册</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">使用</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">账号</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，如手机号或者邮箱所有人提供</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">确实</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">证据，我方将对冒用账号进行注销，并保留追究其责任的权利。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">5</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、用户不得恶意使用</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本平台</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">不得将账号转让、出借、出租、售卖或分享予他人使用。</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">如发现，平台将立即进行封号处理。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">6</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户知悉并同意，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">由于宽带及网络因素，提供服务的过程中可能会需要较长等待时间，此类情况，请用户予以理解。</span>
        </p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">四、用户数据保密</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">1</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本平台提供服务时，基于服务的便利性，需要收集用户的手机号等信息，微思翻译承诺将对该类信息进行完全保密，绝不会泄露给平台以外的第三方机构。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">2</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户同意接收</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">向用户在平台注册的手机号码、邮箱地址发送服务和商业信息</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，用以通知服务或者更改密码等</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。若用户不再需要这些信息，可通知</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">我司</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">取消发送。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">3</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、本平台将对用户上传文档及翻译后的文档进行保密，绝</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">不会将用户的上述数据对外公开或提供给任何第三方，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">且只允许本用户在一定时间范围内进行查阅，超出时间，将自动消除，不予保留备份。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">4</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、如用户使用本平台</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">从事</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">违反国家法律法规的文档进行翻译，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">我方将</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">根据</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">相关</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">法律法规的规定，或行政机关的要求，向行政机关、司法机关或其他</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">执法机关进行</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">披露</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">5</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户在使用的过程中，应遵守以下法律法规：《中华人民共和国保守国家秘密法》</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">《中华人民共和国著作权法》</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">《中华人民共和国计算机信息系统安全保护条例》</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">《计算机软件保护条例》</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">《互联网电子公告服务管理规定》</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">《信息网络传播权保护条例》</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">《中华人民共和国网络安全法》</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">等相关计算机及互联网的其他法律、法规、规定。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">6</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">未经</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">事先明确书面许可，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">禁止</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">以任何方式（包括但不限于机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件）和任何理由自行或委托他人、协助他人获取平台的服务、内容、数据</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。如发现，将保留追究其刑事责任的权利。</span></p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">五、知识产权</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">受国际版权公约、中华人民共和国著作权法、专利法、及其他知识产权方面的法律法规的保护，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译的服务，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">所使用的软件、界面、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">交互等</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">元素、</span><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">LOGO</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、商标、标识、技术等的所有知识产权归</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">北京容智科技发展有限公司</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">所有和享有</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，不允许第三方侵犯行为。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户不得修改、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">改编</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">所使用的软件、技术等，或者创作与之相关的派生作品，不得通过反向工程、反编译或其他类似行为获得其源代码，否则由此引起的一切法律后果由用户负责，将依法追究法律责任。</span>
        </p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">六、用户须知</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">1</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户理解并同意，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">微思翻译提供的机器翻译服务品质是由</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">现有技术和条件</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">所</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">决定的，微思翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">不对提供</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">服务品质</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">作出</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">任何明示或暗示的承诺或保证。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">2</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户理解并同意，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">如出现</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">由于服务器或网络发生故障导致影响平台的正常运行</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">的情况</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">平台将尽快进行修复</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">对于出现不可抗力，导致确实无法在短时间内恢复正常运行的，平台将无法承担由此造成的损失。</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">3</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户理解并同意，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">如为了改善用户体验、提高服务品质，微思翻译将不定时进行更新及维护，由此产生的短时间内的服务异常，敬请谅解。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">4</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户理解并同意，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">如</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">因不可抗力、黑客攻击、网络中断等</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">因素造成使用服务中断，平台将尽快排除故障，提供正常服务，敬请谅解。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">5</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">用户理解并同意，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">平台提供的翻译</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">内容</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">将由网络途径进行上传及下载服务，由于非本平台提供的下载途径获取的文档出现木马等，本平台将不承担任何责任。</span>
        </p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">七、服务的变更、中断及终止</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">1</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">购买服务期满，或者充值型服务用尽之时，本平台将自动停止本用户的服务。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">2</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、用户主动提出要求终止服务的，将以双方沟通结果为准，进行相关处理。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">3</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本平台</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">有权根据</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">其业务</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">情况及用户</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">情况</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，暂时或永久</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">得中断或者终止服务</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">对于有偿服务用户将进行通知</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，并对费用进行核算清算</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，对于无偿服务用户无通知义务</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">。</span></p>
        <h1 style="font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:6pt 0pt; orphans:0; page-break-after:avoid; page-break-inside:avoid; widows:0">
          <span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px; font-weight:bold">八、联系方式</span></h1>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">1</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、用户可通过平台内的反馈功能，进行意见的在线提交，我方将尽快给予答复。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">2</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">、用户还可以通过业务负责人进行信息反馈。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">&#xa0;</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; text-indent:20pt; widows:0"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">本协议</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">内容</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">均应适用</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">于</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">中华人民共和国大陆地区法律</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">，</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">与本协议相关的任何纠纷，双方应协商友好解决。</span>
        </p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">&#xa0;</span></p>
        <p style="text-indent: 20pt; line-height:26px; margin:5pt 0pt"><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">&#xa0;</span></p>
        <p style="text-indent: 20pt; line-height:26px; margin:5pt 0pt 15pt; text-align:right"><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">北京容智科技发展有限公司</span>
        </p>
        <p style="text-indent: 20pt; line-height:26px; margin:5pt 0pt 15pt; text-align:right"><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">2</span><span style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">020</span><span
          style="font-family:Microsoft YaHei; font-weight: 400;color: #333333;font-size:14px">年9月1日</span></p>
        <p style="text-indent: 20pt; font-weight: 400;color: #333333;font-size:14px; line-height: 26px; margin:0pt; orphans:0; widows:0"><span
          style="font-family:'Microsoft YaHei'; font-weight: 400;color: #333333;font-size:14px">&#xa0;</span></p></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Agreement'
}
</script>

<style scoped>
  .hr2 {
    width: 100%;
    background-color: #f5f5f5;
    border: 0;
    z-index: 1;
    margin-top: 0;
    padding: 10px;
  }
</style>
