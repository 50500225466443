const host = process.env.VUE_APP_STORAGE

export function putToken(data) {
  const iframePage = document.getElementById('iframe-page')
  iframePage.contentWindow.postMessage(
    {
      type: 'putToken',
      value: data
    },
    host
  )
}

export function putAuthContext(data) {
  const iframePage = document.getElementById('iframe-page')
  iframePage.contentWindow.postMessage(
    {
      type: 'putAuthContext',
      value: data
    },
    host
  )
}
