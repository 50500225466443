import { authServiceClient } from './service'
import {
  LoginRequest,
  ValicodeRequest,
  RegisterRequest,
  CheckPhoneRequest,
  CheckUserNameRequest,
  UserStatusRequest,
  OutRequest,
  UserPowerRequest,
  UserIpRequest,
  PhoneCodeRequest,
  PhoneLoginRequest,
  CheckPhoneCodeRequest,
  ResetPasswordRequest
} from '../../api/authClient_pb'
const checkPhoneCodeRequest = new CheckPhoneCodeRequest()
const phoneLoginRequest = new PhoneLoginRequest()
const phoneCodeRequest = new PhoneCodeRequest()
const loginRequest = new LoginRequest()
const valicodeRequest = new ValicodeRequest()
const registerRequest = new RegisterRequest()
const checkPhoneRequest = new CheckPhoneRequest()
const checkUserNameRequest = new CheckUserNameRequest()
const userStatusRequest = new UserStatusRequest()
const outRequest = new OutRequest()
const userPowerRequest = new UserPowerRequest()
const userIpRequest = new UserIpRequest()
const resetPasswordRequest = new ResetPasswordRequest()
export {
  loginRequest,
  authServiceClient,
  valicodeRequest,
  registerRequest,
  checkPhoneRequest,
  checkUserNameRequest,
  userStatusRequest,
  outRequest,
  userPowerRequest,
  userIpRequest,
  phoneCodeRequest,
  phoneLoginRequest,
  checkPhoneCodeRequest,
  resetPasswordRequest
}
