import {AuthServicePromiseClient} from '../../api/authClient_grpc_web_pb'
import route from '../router'
import {putAuthContext, putToken} from '../tools/token'
// 从 env 配置文件读取接口域名
const host = process.env.VUE_APP_API
const allInterceptor = function () {
}
allInterceptor.prototype.intercept = function (request, invoker) {
  return invoker(request).then((response) => {
    const metadata = response.getMetadata()
    if (metadata.code) {
      localStorage.setItem('code', metadata.code)
    }
    if (metadata['auth-context'] && metadata.token) {
      putAuthContext(metadata['auth-context'])
      putToken(metadata.token)
    } else {
      if (route.currentRoute.path !== '/login' && route.currentRoute.path !== '/register' && route.currentRoute.path !== '/index' &&
        route.currentRoute.path !== '/recommendations' && route.currentRoute.path !== '/try' && route.currentRoute.path !== '/resetPassword' &&
        route.currentRoute.path !== '/payTip') {
        route.push('/index')
      }
    }
    return response
  })
}

const interceptor = new allInterceptor()
export const authServiceClient = new AuthServicePromiseClient(host, null, {
  unaryInterceptors: [interceptor]
})
